import * as React from "react"
import { Container, Row } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/css/NewsDetail.scss"
import { Link } from "gatsby"
import { AiFillLinkedin } from "react-icons/ai"
import { FaTwitter } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { FiArrowLeft } from "react-icons/fi"

const EdgeqPressKit9 = () => (
  <Layout>
    <Seo title="EdgeQ | EdgeQ Closes $75M Series-B Investment and Ramps" />

    <section className="News-detail">
      <Container>
        <Row>
          <div className="detail-head">
            <Link to="/media/#August" className="back-btn">
              <FiArrowLeft /> Company Announcements
            </Link>

            <h2>
              EdgeQ Closes $75M Series-B Investment and Ramps to Customers’
              Demand for Its Award-Winning 5G+AI Base Station-on-a-Chip for 5G
              Networks
            </h2>
          </div>

          <div className="detail-para">
            <p className="text-left">
              <i>
                Lattice Semiconductor CEO Jim Anderson Joins the Board of
                Directors as the Company Enters into Customer Deployments of
                Private 5G and Open RAN Networks
              </i>
            </p>

            <div className="ajit-social">
              <ul className="social-icons">
                <li>
                  <a
                    aria-label="Save"
                    href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillLinkedin />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="Save"
                    href="https://twitter.com/edgeq_inc?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="Save"
                    href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube />
                  </a>
                </li>
              </ul>
            </div>

            <p>
              <b>SANTA CLARA, CA</b> – APRIL 19, 2023 –{" "}
              <Link to="/">EdgeQ Inc</Link>, a leader in 5G wireless
              infrastructure, announced today a $75 million investment round of
              funding as well as the appointment of Lattice Semiconductor CEO
              and President, Jim Anderson, to the company’s Board of Directors.
              EdgeQ will use the funding to ramp production of its award-winning
              5G + AI “Base Station-on-a-Chip” to meet customer demand and to
              develop its next generation chip. The $75 million funding consists
              of both institutional and strategic investors, including Phaistos
              Investment Fund, Strategic Development Fund (SDF) the investment
              arm of the United Arab Emirates Tawazun Council, Singapore-based
              global investor EDBI, IRONGREY, ST Engineering, Yaletown,
              ClearSky, and other existing investors
            </p>

            <p>
              EdgeQ’s revolutionary approach to wireless infrastructure unifies
              both 4G and 5G onto a single chip that can elastically scale in
              performance and features via software upgrades. EdgeQ is the only
              company offering multi-mode 4G+5G convergence along with a
              production grade 4G+5G PHY layer for O-RAN Distributed Unit (DU)
              and small cells.
            </p>

            <p>
              “It is really exciting to see a rich mix of institutional and
              strategic investors validate the mission and spirit of EdgeQ. We
              are here to motivate new and existing players, innovating to
              deploy private 5G networks and O-RAN networks in simplistic,
              affordable terms,” said Vinay Ravuri, CEO and co-founder of EdgeQ.
              “By removing the friction and price premium that traditionally
              accompanies cellular deployment, we have envisioned a new backbone
              that is simple, intuitive, and open. And this disruption is
              available now.”
            </p>

            <p>
              The fabric of wireless infrastructure rests on access to silicon
              innovation that is designed expressively for a rich cross section
              of transformative applications, ranging from industrial 4.0
              automation to digital education to autonomous vehicles to
              satellite communications and beyond. With the latest investment
              round, EdgeQ will be the first to address the fast growing,
              multi-billion dollar 5G infrastructure TAM with a single chip that
              converges connectivity and compute at the edge.
            </p>

            <p>
              “EdgeQ is built on strong fundamentals that appeals to investors,
              technologists, and customers. Particularly in the 5G wireless
              space, EdgeQ possesses the rare blend of ability and aptitude to
              execute on a compelling vision that will profoundly reshape how
              the industry conceives and consumes 5G,” said Jim Anderson, CEO
              and President of Lattice Semiconductor. “This is a really exciting
              time to join as an independent board member and help EdgeQ through
              the next phase of growth and market successes.”
            </p>

            <p>
              EdgeQ has developed the world’s first “Base station-on-a-Chip,”
              giving operators, cloud service providers and enterprises the
              revolutionary ability to build, configure, and deploy public and
              private networks that are simple, scalable, and affordable. The
              massively integrated 4G+5G+AI chip uniquely leverages both RISC-V
              and ARM architectures to compact at an unprecedented small scale
              an entire rack of complex and high-performance base station
              communications equipment into a tiny and power efficient envelop.
              The platform comes feature complete, including a production-grade
              Physical Layer (PHY) software that governs all the essential
              protocols and features of 4G/5G.
            </p>

            <p>
              Recently awarded the coveted “CTO Choice Award” at Mobile World
              Congress Barcelona 2023, EdgeQ’s converged 4G+5G+AI platform
              enables operators and enterprises to deploy systems at scale,
              implement wholesale upgrades without ripping and replacing their
              current network configuration, and frees the market from vendor
              lock-in. The solution is available now. And customer deployments
              are expected this year. Recently awarded the coveted “CTO Choice
              Award” at Mobile World Congress Barcelona 2023, EdgeQ’s converged
              4G+5G+AI platform enables operators and enterprises to deploy
              systems at scale, implement wholesale upgrades without ripping and
              replacing their current network configuration, and frees the
              market from vendor lock-in. The solution is available now. And
              customer deployments are expected this year.
            </p>

            <p>
              To learn more about how EdgeQ is pioneering the 5G infrastructure
              market, please visit <Link to="/">www.edgeq.io</Link>.
            </p>

            <b>About EdgeQ</b>
            <br />
            <br />
            <p>
              EdgeQ is a leading innovator in 5G systems-on-a-chip. The company
              is headquartered in Santa Clara, CA, with offices in San Diego, CA
              and Bangalore, India. Led by executives from Qualcomm, Intel, and
              Broadcom, EdgeQ is pioneering converged connectivity and AI that
              is fully software-customizable and programmable. The company is
              backed by world-renowned investors. To learn more about EdgeQ,
              visit <Link to="/">www.edgeq.io</Link>
            </p>
            {/* <b>Contacts</b>
              <br />
              <br />
              <a target="_blank" href="https://goo.gl/maps/qwqsKwcTcEVokx1e6">Jordan Tewell, 10Fold Communications</a>
              <br />
              <a href="mailto:edgeq@10fold.com">edgeq@10fold.com</a>
              <br />
              <a href="tel:+4156666066">(415) 666-6066</a> */}
          </div>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default EdgeqPressKit9
